import React from 'react'
import Layout from '../Layout/Layout'
import Head from '../Components/Head'

function AboutUs() {
  return (
    <Layout>
    <div className=' min-h-screen container mx-auto px-2 my-6'>
      <Head title='About Us'/>
      <div className=' xl:py-20 py-10 px-4'>
        <div className=' grid grid-flow-row xl:grid-cols-2 gap-4 xl:gap-16 items-center'>
          <div>
            <h3 className=' text-xl lg:text-3xl mb-4 font-semibold'>
              Welcome to LeHulu TV
            </h3>
            <div className=' mt-3 text-sm leading-8 text-text'>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
          <div className=' grid md:grid-cols-2 gap-6 mt-8'>
            <div className=' p-8 bg-dry rounded-lg'>
              <span className=' text-3xl block font-extrabold mt-4'>
                10K
              </span>
              <h4 className=' text-lg font-semibold my-2'>Listed Movies</h4>
              <p className=' mb-0 text-text leading-7 text-sm'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
            <div className=' p-8 bg-dry rounded-lg'>
              <span className=' text-3xl block font-extrabold mt-4'>
                100K
              </span>
              <h4 className=' text-lg font-semibold my-2'>Lovely Users</h4>
              <p className=' mb-0 text-text leading-7 text-sm'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
          </div>
          </div>
          <div className=' mt-10 lg:mt-0'>
            <img src='/images/aboutus.png' alt='aboutus' className=' w-full xl:block hidden h-header rounded-lg object-cover' />
          </div>
        </div>
      </div>
    </div>
    </Layout>
  )
}

export default AboutUs