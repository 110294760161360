import React, { useEffect, useState } from "react";
import { FaVolumeMute, FaVolumeUp, FaPlay, FaShareAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import FlexMovieItems from "../FlexMovieItems";
import Rating from "../Stars";

function MovieInfo({ movie, setModalOpen }) {
  const [isMuted, setIsMuted] = useState(false);
  const [sponsorClass, setSponsorClass] = useState("slide-in");
  const [showAd, setShowAd] = useState(true);

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSponsorClass("slide-out");
      // Hide the ad after the slide-out animation completes
      setTimeout(() => setShowAd(false), 300); // Adjust the timeout to match your slide-out duration
    }, 15000); // Slide out after 15 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full xl:h-screen relative text-white -mt-[73px] overflow-hidden">
      <video
        autoPlay
        loop
        muted={isMuted}
        className="w-full inline-block h-screen object-cover"
        src={movie?.videoTrailer}
        type="video/mp4"
        title={movie?.name}
      />
      <div className="bg-gradient-to-t from-main via-main/80 to-dry/60 flex-colo bg-opacity-90 absolute top-0 left-0 right-0 bottom-0">
        <div className="container px-3 mx-auto 2xl:px-32 xl:grid grid-cols-3 flex-colo py-10 lg:py-20 gap-8 mt-20">
          <div className="xl:col-span-1 w-full xl:order-none order-last h-header bg-dry border border-gray-800 rounded-lg overflow-hidden lg:block hidden">
            <img
              src={
                movie?.titleImage
                  ? movie?.titleImage
                  : "/images/movies/bannerTitle.png"
              }
              alt={movie?.name}
              className="w-full"
            />
          </div>
          <div className="lg:col-span-2 md:grid grid-cols-5 gap-4 items-center">
            <div className="col-span-3 flex flex-col gap-10">
              <div className="flex gap-6">
                <h1 className="xl:text-4xl capitalize font-sans text-2xl font-bold">
                  {movie?.name}
                </h1>
                <div className="flex items-center">
                  <button
                    onClick={handleMuteToggle}
                    className="p-2 bg-gray-800 bg-opacity-50 rounded-full text-gray-400 border border-gray-500"
                  >
                    {isMuted ? (
                      <FaVolumeMute size={21} />
                    ) : (
                      <FaVolumeUp size={21} />
                    )}
                  </button>
                </div>
              </div>
              <div className="flex items-center gap-4 font-medium text-dryGray">
                <div className="flex-colo bg-subMain text-xs px-2 py-1">
                  HD 4K
                </div>
                <FlexMovieItems movie={movie && movie} />
              </div>
              <div className="flex gap-3 text-star">
                <Rating value={movie?.rate} />
              </div>
              <p className="text-text text-sm leading-7">{movie?.desc}</p>
              <div className="flex gap-6">
                <div className="grid sm:grid-cols-5 grid-cols-4 gap-4 p-6 bg-main bg-opacity-50 border border-gray-800 rounded-lg">
                  <div className="col-span-1 flex-colo border-r border-border">
                    <button
                      onClick={() => setModalOpen(true)}
                      className="w-10 h-10 flex-colo rounded-lg bg-white bg-opacity-20"
                    >
                      <FaShareAlt />
                    </button>
                  </div>
                  <div className="col-span-2 flex-colo font-medium text-sm">
                    <p>
                      Language :{" "}
                      <span className="ml-2 truncate">{movie?.language}</span>
                    </p>
                  </div>
                  <div className="sm:col-span-2 col-span-3 flex justify-end font-medium text-sm">
                    <Link
                      to={`/login?redirect=/watch/${movie?._id}`}
                      className="bg-dry bg-opacity-50 py-4 hover:bg-subMain transitions border-2 border-subMain rounded-full flex-rows gap-4 w-full sm:py-3"
                    >
                      <FaPlay className="h-3 w-3" /> Watch
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Large screen AD Image */}
            {showAd && movie?.bigAdImage && (
              <div
                className={`col-span-2 md:mt-0 mt-2 justify-end lg:grid hidden rounded-sm ${sponsorClass}`}
              >
                <img
                  src={movie.bigAdImage}
                  className="w-40 h-auto object-cover rounded-md"
                />
              </div>
            )}

            {/* Mobile AD Image */}
            {showAd && movie?.mobileAdImage && (
              <div
                className={`col-span-2 md:mt-0 mt-2 justify-end grid lg:hidden ${sponsorClass}`}
              >
                <img
                  src={movie.mobileAdImage}
                  className="w-full h-40 object-cover"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MovieInfo;
