import React, { useEffect } from "react";
import Layout from "../Layout/Layout";
import Banner from "../Components/Home/Banner";
import PopularMovies from "../Components/Home/PopularMovies";
import Promos from "../Components/Home/Promos";
import TopRated from "../Components/Home/TopRated";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMoviesAction,
  getRandomMoviesAction,
  getTopRatedMoviesAction,
} from "../Redux/Actions/MoviesActions";
import toast from "react-hot-toast";
import AdBanner from "../Components/Home/AdBanner";
import Loader from "../Components/Notfications/Loader";

function HomeScreen() {
  const dispatch = useDispatch();

  // useSelector
  const {
    isLoading: randomLoading,
    isError: randomError,
    movies: randomMovies,
  } = useSelector((state) => state.getRandomMovies);
  const {
    isLoading: topLoading,
    isError: topError,
    movies: topMovies,
  } = useSelector((state) => state.getTopRatedMovies);
  const { isLoading, isError, movies } = useSelector(
    (state) => state.getAllMovies
  );

  // Combined loading state
  const isLoadingAll = isLoading || randomLoading || topLoading;

  // useEffect
  useEffect(() => {
    // get random movies
    dispatch(getRandomMoviesAction());
    // get all movies
    dispatch(getAllMoviesAction({}));
    // get top rated movies
    dispatch(getTopRatedMoviesAction());
    // errors
    if (isError || randomError || topError) {
      toast.error("Something went wrong!");
    }
  }, [dispatch, isError, randomError, topError]);

  return (
    <Layout>
      <div className="mx-auto min-h-screen mb-6">
        {isLoadingAll ? (
          <div className="flex justify-center items-center h-screen"> {/* or use a specific height like h-64 */}
          <Loader />
        </div>
        
        ) : (
          <>
            <Banner movies={randomMovies} isLoading={isLoading} />
            <div className="z-100 relative md:px-12 px-3">
              <PopularMovies movies={randomMovies} isLoading={randomLoading} />
            </div>
            <AdBanner movies={movies} isLoading={isLoading} />
            <div className="z-100 relative md:px-6 px-3">
              <Promos />
              <TopRated movies={topMovies} isLoading={topLoading} />
            <AdBanner movies={movies} isLoading={isLoading} />
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default HomeScreen;
