import React, { useEffect, useState } from 'react';
import { FaAngleDown, FaCheck } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { LanguageData, RatesData, TimesData, YearData } from '../Data/FilterData';
import { getAllMoviesAction } from '../Redux/Actions/MoviesActions';

function Dropdown({ value, onChange, items, isOpen, onToggle }) {
  return (
    <div className="relative">
      <button
        className="relative border border-gray-800 w-full text-white bg-main rounded-lg cursor-default py-4 pl-6 pr-10 text-left text-xs"
        onClick={onToggle}
      >
        <span className="block truncate">{value.title}</span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <FaAngleDown className="h-5 w-5" aria-hidden="true" />
        </span>
      </button>
      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-dry border border-gray-800 text-dryGray rounded-md shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          {items.map((item, index) => (
            <div
              key={index}
              className={`relative cursor-default select-none py-2 pl-10 pr-4 ${value.title === item.title ? 'bg-subMain text-white' : 'text-main'}`}
              onClick={() => {
                onChange(item);
                onToggle(); // Close the dropdown after selection
              }}
            >
              <span className={`block truncate text-white ${value.title === item.title ? 'font-semibold' : 'font-normal'}`}>
                {item.title}
              </span>
              {value.title === item.title && (
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <FaCheck className="h-4 w-4" aria-hidden="true" />
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function Filters(props) {
  const dispatch = useDispatch();
  const {
    categories,
    category,
    setCategory,
    language,
    setLanguage,
    year,
    setYear,
    times,
    setTimes,
    rates,
    setRates
  } = props?.data

  const [openDropdown, setOpenDropdown] = useState(null); // Track which dropdown is open

//   useEffect(() => {
//     dispatch(getAllMoviesAction({
//       category: category?.title === "All Categories" ? "" : category?.title,
//       language: language?.title === "Sort by Language" ? "" : language?.title,
//       year: year?.title.replace(/\D/g, ""),
//       time: times?.title.replace(/\D/g, ""),
//       rate: rates?.title.replace(/\D/g, ""),
//       search: ""
//     }));
//   }, [category, language, year, times, rates, dispatch]);

  const handleToggleDropdown = (dropdownName) => {
    setOpenDropdown(prev => (prev === dropdownName ? null : dropdownName));
  };

  return (
    <div className='my-6 bg-dry border text-dryGray border-gray-800 grid md:grid-cols-5 grid-cols-2 lg:gap-12 gap-2 rounded p-6'>
      <Dropdown
        value={category}
        onChange={setCategory}
        items={categories?.length > 0 ? [{ title: "All Categories" }, ...categories] : [{ title: "No category found" }]}
        isOpen={openDropdown === 'category'}
        onToggle={() => handleToggleDropdown('category')}
      />
      <Dropdown
        value={language}
        onChange={setLanguage}
        items={LanguageData}
        isOpen={openDropdown === 'language'}
        onToggle={() => handleToggleDropdown('language')}
      />
      <Dropdown
        value={year}
        onChange={setYear}
        items={YearData}
        isOpen={openDropdown === 'year'}
        onToggle={() => handleToggleDropdown('year')}
      />
      <Dropdown
        value={times}
        onChange={setTimes}
        items={TimesData}
        isOpen={openDropdown === 'times'}
        onToggle={() => handleToggleDropdown('times')}
      />
      <Dropdown
        value={rates}
        onChange={setRates}
        items={RatesData}
        isOpen={openDropdown === 'rates'}
        onToggle={() => handleToggleDropdown('rates')}
      />
    </div>
  );
}

export default Filters;
