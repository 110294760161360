import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { IfMovieLiked, LikeMovie } from "../Context/Functionalities";

function Movie({ movie }) {
  const { isLoading } = useSelector((state) => state.userLikeMovie);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);

  // if liked function
  const isLiked = IfMovieLiked(movie);

  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    if (videoRef?.current) {
      setTimeout(() => {
        videoRef?.current?.play().catch((error) => {
          console.error("Error playing video:", error);
        });
      }, 1000); // Delay playing the video by 1000 ms
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      // Reset video to start after a short delay to prevent the "interrupted" error
      setTimeout(() => {
        videoRef.current.currentTime = 0;
      }, 100);
    }
  };

  return (
    <div
      className="border border-border hover:scale-105 md:hover:scale-125 transition-transform relative rounded overflow-hidden group md:hover:z-10 md:hover:delay-500 md:hover:overflow-hidden hover:shadow-2xl hover:shadow-black"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link to={`/movie/${movie?._id}`} className="w-full">
        {/* Image */}
        <img
          src={movie?.image ? movie?.image : "images/movies/banner.png"}
          alt={movie?.name}
          className="w-full h-64 object-cover transition-opacity md:hover:delay-1000 "
        />

        {/* Video (hidden by default) */}
        <video
          ref={videoRef}
          src={movie?.videoTrailer}
          className="w-full h-full object-cover absolute top-0 left-0 transition-opacity duration-300 opacity-0 md:hover:opacity-100 md:hover:delay-1000"
          loop
          muted
        />
      </Link>

      <div className="absolute flex-btn gap-2 bottom-0 right-0 left-0 bg-main bg-opacity-50 backdrop-blur-sm text-white px-4 py-2">
        <h3 className="font-semibold truncate">{movie?.name}</h3>
        <p className="text-sm truncate">{movie?.description}</p>
        <button
          onClick={() => LikeMovie(movie, dispatch, userInfo)}
          disabled={isLiked || isLoading}
          className={`h-6 w-6 text-sm flex-colo transitions
          ${isLiked ? "bg-subMain" : "bg-white bg-opacity-30"}
          md:hover:bg-subMain rounded-md text-white`}
        >
          <FaPlus />
        </button>
      </div>
    </div>
  );
}

export default Movie;
