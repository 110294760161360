import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiUploadCloud } from 'react-icons/fi';
import Loader from './Notfications/Loader';
import { uploadImageService } from '../Redux/APIs/ImageUploadService';

function Uploader({ setImageUrl }) {
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState(0); // Add a state to manage the key

    // upload file
    const onDrop = useCallback(
        async (acceptedFiles) => {
            setLoading(true); // Start loading
            const file = new FormData();
            file.append("file", acceptedFiles[0]);
            const data = await uploadImageService(file, setLoading);
            setImageUrl(data);
            setLoading(false); // End loading
            setKey(prevKey => prevKey + 1); // Increment the key to refresh the component
        },
        [setImageUrl]
    );

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        multiple: false,
        onDrop,
    });

    return (
        <div key={key} className='w-full text-center flex-colo gap-6'> {/* Use key prop here */}
            {
                loading ? (
                    <div className='px-6 w-full py-8 border-2 border-border border-dashed bg-dry rounded-md'>
                        <Loader />
                    </div>
                ) : (
                    <div {...getRootProps()} className='px-6 w-full py-8 border-2 border-border border-dashed bg-main rounded-md cursor-pointer'>
                        <input {...getInputProps()} />
                        <span className='mx-auto flex-colo text-subMain text-3xl'>
                            <FiUploadCloud />
                        </span>
                        <p className='text-sm mt-2'>Drag your image here</p>
                        <em className='text-xs text-border'>
                            {isDragActive ? "Drop it like it's hot!" :
                                isDragReject ? "Unsupported file type..." : "Only .jpg and .png files will be accepted"}
                        </em>
                    </div>
                )
            }
        </div>
    );
}

export default Uploader;
