import React from 'react';
import MainDrawer from './MainDrawer';
import { Link, NavLink } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { BiPhoneCall } from 'react-icons/bi';
import { BsCollectionPlay } from 'react-icons/bs';
import { FaFacebook, FaInstagram, FaTelegram, FaTiktok, FaYoutube } from 'react-icons/fa';

function MenuDrawer({ drawerOpen, toggleDrawer }) {

  const active = 'bg-dryGray text-subMain';
  const hover = 'hover:bg-dry';
  const inActive = 'rounded sm:gap-10 font-medium text-sm transitions flex gap-6 items-center sm:px-8 px-4 py-4 items-center';
  const Hover = ({ isActive }) =>
    isActive ? `${active} ${inActive}` : `${inActive} ${hover}`;

  const Links = [
    {
      name: 'Movies',
      link: '/movies',
      icon: BsCollectionPlay,
    },
    {
      name: 'About Us',
      link: '/about-us',
      icon: HiOutlineUserGroup,
    },
    {
      name: 'Contact Us',
      link: '/contact-us',
      icon: BiPhoneCall,
    },
  ];

  const LinkDatas = [
    {
        icon: FaFacebook,
        link: 'https://www.facebook.com/lehulutv'
    },
    {
        icon: FaInstagram,
        link: 'https://www.instagram.com/lehulutv'
    },
    {
        icon: FaTelegram,
        link: 'https://t.me/lehulu_tv'
    },
    {
        icon: FaYoutube,
        link: 'https://www.youtube.com/lehulutv'
    },
    {
        icon: FaTiktok,
        link: 'https://www.tiktok.com/@lehulutv'
    }
  ]

  return (
    <MainDrawer drawerOpen={drawerOpen} closeDrawer={toggleDrawer}>
      <div
        className='fixed top-0 left-0 w-full h-full flex flex-col justify-between items-center bg-main text-white z-[9999]'
      >
        <div className='w-full flex-btn h-16 px-6 py-6 bg-dry'>
          <Link onClick={toggleDrawer} to='/'>
            <img src='/images/logo.png' alt='logo' className='w-28 h-28 object-contain' />
          </Link>
          <button
            onClick={toggleDrawer}
            type='button'
            className='transition w-10 h-10 flex-colo text-base text-subMain bg-white rounded-full hover:bg-subMain hover:text-white'
          >
            <IoClose />
          </button>
        </div>
        {/* Menu Links */}
        <div className='w-full overflow-y-scroll flex-grow max-h-full'>
            <div className=' pb-12 pt-4'>
          {Links.map((link, index) => (
            <NavLink to={link.link} key={index} onClick={toggleDrawer} className={Hover}>
              <link.icon className='text-lg' /> {link.name}
            </NavLink>
          ))}
          </div>
          <div className=' flex-rows gap-6 w-full'>
            {LinkDatas.map((link, index) => (
                <a href={link.link} key={index} target='_blank' rel='noreferrer' className=' flex-colo w-12 h-12 transition hover:bg-subMain text-lg bg-white rounded bg-opacity-30'>
                    <link.icon />
                </a>
            ))}
        </div>
        </div>
      </div>
    </MainDrawer>
  );
}

export default MenuDrawer;
