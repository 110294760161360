import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { Link, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { FaHeart, FaPlay } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getMovieByIdAction } from "../Redux/Actions/MoviesActions";
import Loader from "../Components/Notfications/Loader";
import { RiMovie2Line } from "react-icons/ri";
import { IfMovieLiked, LikeMovie } from "../Context/Functionalities";

function WatchPage() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [videoState, setVideoState] = useState("thumbnail");
  const [countdown, setCountdown] = useState(0);

  const sameClass = "w-full gap-6 flex-colo min-h-screen";

  const { isLoading, isError, movie } = useSelector(
    (state) => state.getMovieById
  );
  const { isLoading: likeLoading } = useSelector(
    (state) => state.userLikeMovie
  );
  const { userInfo } = useSelector((state) => state.userLogin);

  const isLiked = (movie) => IfMovieLiked(movie);

  const adDuration = movie?.adDuration;

  useEffect(() => {
    dispatch(getMovieByIdAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    let timer;
    if (videoState === "ad") {
      setCountdown(adDuration / 1000);

      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      setTimeout(() => {
        setVideoState("main");
      }, adDuration);
    }

    return () => {
      clearInterval(timer);
      setCountdown(0);
    };
  }, [videoState, adDuration]);

  const handlePlayClick = () => {
    setVideoState("ad");
  };

  return (
    <Layout>
      <div className="container mx-auto p-6 mb-12">
        {!isError && (
          <div className="flex-btn flex-wrap mb-6 gap-2 bg-main rounded border border-gray-800 p-6">
            <Link
              to={`/movie/${movie?._id}`}
              className="md:text-xl text-sm flex gap-3 items-center font-bold text-dryGray"
            >
              <BiArrowBack /> {movie?.name}
            </Link>
            <div className="flex-btn sm:w-auto w-full gap-5">
              <button
                onClick={() => LikeMovie(movie, dispatch, userInfo)}
                disabled={isLiked(movie) || likeLoading}
                className={`bg-white hover:text-subMain 
                                ${
                                  isLiked(movie) ? "text-subMain" : "text-white"
                                }
                               transitions bg-opacity-30 rounded px-4 py-3 text-sm`}
              >
                <FaHeart />
              </button>
            </div>
          </div>
        )}

        {/* Video Section */}
        {videoState === "ad" ? (
          // Ad Video
          <div className="relative w-full h-full">
            <video
        autoPlay
        loop
        className="w-full h-full rounded"
        src={movie?.adVideo}
        controls={false}
        onPlay={() => {
          setVideoState("ad");
        }}
      ></video>
            <div className="text-[14px] absolute top-0 left-0 m-4 text-white text-xl font-bold">
              AD skips in {countdown > 0 ? countdown : 0} sec
            </div>            
            <div className="text-[14px] absolute top-0 right-0 m-4 text-white text-xl hover:font-bold border px-6 rounded-md backdrop-blur-lg bg-black bg-opacity-30 cursor-pointer hover:shadow-lg hover:bg-opacity-35">
              Get Premium
              </div>
          </div>
        ) : videoState === "main" ? (
          // Main Movie Video
          <>
            <video controls autoPlay className="w-screen h-full rounded">
              <source src={movie?.video} type="video/mp4" title={movie?.name} />
            </video>
          </>
        ) : (
          // Thumbnail with Play Button
          <div className="w-full h-screen rounded-lg overflow-hidden relative">
            {isLoading ? (
              <div className={sameClass}>
                <Loader />
              </div>
            ) : isError ? (
              <div className={sameClass}>
                <div className="flex-colo w-24 h-24 p-5 mb-4 rounded-full bg-main text-subMain text-4xl">
                  <RiMovie2Line />
                </div>
                <p className="text-border text-sm">Movie not found</p>
              </div>
            ) : (
              <>
                <div className="absolute top-0 left-0 bottom-0 right-0 bg-main bg-opacity-30 flex-colo">
                  <button
                    onClick={handlePlayClick}
                    className="bg-white text-subMain flex-colo border border-subMain rounded-full w-20 h-20 font-medium text-xl"
                  >
                    <FaPlay />
                  </button>
                </div>
                <img
                  src={movie?.image || "images/user.png"}
                  alt={movie?.name}
                  className="w-full h-full object-cover rounded-lg"
                />
              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}

export default WatchPage;
