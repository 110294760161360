import { combineReducers, configureStore } from "@reduxjs/toolkit"
import * as User from "./Reducers/userReducers"
import * as Categories from "./Reducers/CategoriesReducer"
import * as Movies from "./Reducers/MoviesReducer"

const rootReducer = combineReducers({
    // user reducers
    userLogin: User.userLoginReducer,
    userRegister: User.userRegisterReducer,
    userUpdateProfile: User.userUpdateProfileReducer,
    userDeleteProfile: User.userDeleteProfileReducer,
    userChangePassword: User.userChangePasswordReducer,
    userGetFavoriteMovies: User.userGetFavoriteMoviesReducer,
    userDeleteFavoriteMovies: User.userDeleteFavoriteMoviesReducer,
    adminGetAllUsers: User.adminGetAllUsersReducer,
    adminDeleteUser: User.adminDeleteUserReducer,
    userLikeMovie: User.userLikeMovieReducer,

    // Category reducers
    categoryGetAll: Categories.getAllCategoriesReducer,
    categoryCreate: Categories.createCategoryReducer,
    categoryUpdate: Categories.updateCategoryReducer,
    categoryDelete: Categories.deleteCategoryReducer,

    // Movies reducers
    getAllMovies: Movies.moviesListReducer,
    getRandomMovies: Movies.moviesRandomReducer,
    getMovieById: Movies.movieDetailsReducer,
    getTopRatedMovies: Movies.movieTopRatedReducer,
    createReview: Movies.createReviewReducer,
    deleteMovie: Movies.deleteMovieReducer,
    deleteAllMovies: Movies.deleteAllMoviesReducer,
    createMovie: Movies.createMovieReducer,
    casts: Movies.CastsReducer,
    updateMovie: Movies.updateMovieReducer,
});

// get userInfo from localStorage
const userInfoFromStorage = localStorage.getItem("userInfo")
   ? JSON.parse(localStorage.getItem("userInfo"))
   : null;

// initialState 
const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
}


export const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
})