export const LanguageData = [
    { title: "Sort by Language" },
    { title: "English" },
    { title: "Amharic" },
]

export const RatesData = [
    { title: "Sort by Rates" },
    { title: "1 Star" },
    { title: "2 Stars" },
    { title: "3 Stars" },
    { title: "4 Stars" },
    { title: "5 Stars" },
]

export const TimesData = [
    { title: "Sort by Hours" },
    { title: "1 Hour" },
    { title: "2 Hour" },
    { title: "3 Hour" },
    { title: "4 Hour" },
    { title: "5 Hour" },
    { title: "6 Hour" },
    { title: "7 Hour" },
]

export const YearData = [
    { title: "Sort by Year" },
    { title: "2024" },
    { title: "2023" },
    { title: "2022" },
    { title: "2021" },
    { title: "2019" },
    { title: "2018" },
    { title: "2017" },
    { title: "2016" },
    { title: "2015" },
    { title: "2014" },
    { title: "2013" },
    { title: "2012" },
    { title: "2011" },
    { title: "2010" },
    { title: "2009" },
    { title: "2008" },
    { title: "2007" },
    { title: "2006" },
    { title: "2005" },
    { title: "2004" },
    { title: "2003" },
    { title: "2002" },
    { title: "2001" },
    { title: "2000" },
]