import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef } from 'react';

function MainModals({ modalOpen, setModalOpen, children }) {
  const cancelButtonRef = useRef(null);

  return (
    <>
      {/* Use modalOpen as the show prop */}
      <Transition show={modalOpen} as={Fragment} appear>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto text-center"
          initialFocus={cancelButtonRef}
          onClose={() => setModalOpen(false)}
        >
          {/* Overlay */}
          <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"></div>

          {/* Modal Content Wrapper */}
          <div className="flex items-center justify-center min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-transparent rounded-lg shadow-xl transform transition-all w-full p-6">
                {/* Actual Modal Content */}
                {children}
              </Dialog.Panel>
            </Transition.Child>
            <div className='absolute right-5 top-5'>
                <button onClick={() => setModalOpen(false)} type='button' className=' transition w-10 h-10 flex-colo text-base font-bold text-white bg-subMain rounded-full hover:bg-white hover:text-subMain'></button>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default MainModals;
