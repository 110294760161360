import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FaSearch, FaHeart } from "react-icons/fa";
import { CgUser } from "react-icons/cg";
import { useSelector } from "react-redux";

function NavBar() {
  const [search, setSearch] = useState("");
  const [hasScrolled, setHasScrolled] = useState(false);
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { likedMovies } = useSelector((state) => state.userGetFavoriteMovies);
  const hover = "hover:text-subMain transitions text-white";
  const Hover = ({ isActive }) => (isActive ? "text-subMain" : hover);

  const handleSearch = (e) => {
    e.preventDefault();
    if (search.trim()) {
      navigate(`/movies/${search}`);
      setSearch(search);
    } else {
      navigate(`/movies`);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`sticky top-0 z-20 transition-colors duration-300 ${
        hasScrolled
          ? "bg-main bg-opacity-60 backdrop-blur-md shadow-md"
          : "bg-transparent"
      }`}
    >
      {/* Large-screen NavBar */}
      <div className="lg:block hidden">
        <div className="container mx-auto py-3 px-2 flex justify-between items-center">
          <div className="grid grid-cols-4 items-center">
            {/* Logo */}
            <Link to="/">
            <div className="flex items-center">
                <img
                  src="/images/logo.png"
                  alt="logo"
                  className="w-full h-12 object-contain"
                />
              <h1 className="font-bold whitespace-nowrap">LEHULU TV</h1>
            </div>
            </Link>
          </div>

          {/* Menus */}
          <div className=" font-medium text-sm flex items-center justify-between">
            <div className="col-span-10 font-medium text-sm hidden xl:gap-14 2xl:gap-20 justify-between lg:flex lg:justify-end items-center">
              {/* Search Form */}
            <div className=" flex justify-end">
              <div className="bg-dryGray bg-opacity-20 rounded-lg overflow-hidden transition-all duration-300 hover:w-full w-12 flex items-center">
                <form
                  onSubmit={handleSearch}
                  className="flex items-center w-full text-sm gap-2"
                >
                  <button
                    type="submit"
                    className="text-white w-12 flex-colo h-9 px-3 rounded"
                  >
                    <FaSearch className="mx-4" />
                  </button>
                  <input
                    type="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search Movie"
                    className="font-medium placeholder:text-gray-300 text-sm w-full h-9 bg-transparent border-none px-2 text-white transition-all duration-300 ease-in-out"
                  />
                </form>
              </div>
            </div>
              {/* <NavLink to="/movies" className={Hover}>
                Movies
              </NavLink>
              <NavLink to="/about-us" className={Hover}>
                About Us
              </NavLink>
              <NavLink to="/contact-us" className={Hover}>
                Contact Us
              </NavLink> */}
              <NavLink to="/favorites" className={`${Hover} relative`}>
                {likedMovies?.length > 0 ? (
                  <>
                    <FaHeart className="w-6 h-6 " />
                    <div className="w-5 h-5 flex-colo rounded-full text-xs bg-subMain text-white absolute -top-5 -right-1">
                      {likedMovies?.length}
                    </div>
                  </>
                ) : (
                  <FaHeart className="w-6 h-6" />
                )}
              </NavLink>
              <NavLink
                to={
                  userInfo?.isAdmin
                    ? "/dashboard"
                    : userInfo
                    ? "/profile"
                    : "/login"
                }
                className={Hover}
              >
                {userInfo ? (
                  <img
                    src={
                      userInfo?.image
                        ? userInfo?.image
                        : "/images/users/user.png"
                    }
                    alt={userInfo.fullName}
                    className="w-8 h-8 rounded-lg border object-cover border-subMain"
                  />
                ) : (
                  <CgUser className="w-8 h-8" />
                )}
              </NavLink>
            </div>
          </div>
        </div>
      </div>


      {/* Mobile NavBar */}
      <div className="container mx-auto py-3 px-2 lg:hidden flex justify-between items-center">
        {/* Logo */}
        <div className="">
          <Link to="/">
            <img
              src="/images/logo.png"
              alt="logo"
              className="w-full h-12 object-contain"
            />
          </Link>
        </div>

        {/* Search Form */}
        <div className=" flex justify-end">
          <div className="bg-dryGray bg-opacity-20 rounded-lg overflow-hidden transition-all duration-300 hover:w-full w-12 flex items-center">
            <form
              onSubmit={handleSearch}
              className="flex items-center w-full text-sm"
            >
              <button type="submit" className="text-white h-9 px-4 rounded">
                <FaSearch />
              </button>
              <input
                type="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Movie"
                className="font-medium placeholder:text-gray-300 placeholder:font-light text-sm w-full h-9 bg-transparent border-none pr-2 text-white transition-all duration-300 ease-in-out"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
