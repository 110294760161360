import React from 'react'
import { Link } from 'react-router-dom'
import { BiHomeAlt } from 'react-icons/bi'

function NotFound() {
  return (
    <div className=' flex-colo w-full min-h-screen text-white bg-main lg:py-20 py-10 px-6'>
      <img className=' w-full h-96 object-contain' src='/images/404.png' alt='notfound' />
      <Link to='/' className=' bg-subMain text-white flex-rows gap-4 font-medium py-3 px-6 hover:text-main transitions rounded-md'>
        <BiHomeAlt/> Go Back Home
      </Link>
    </div>
  )
}

export default NotFound